import { PaymentStepType } from "app/payment/usePayment";
import { AsButton } from "app/subframe/components/AsButton";
import { LineItems } from "app/subframe/components/LineItems";
import * as React from "react";

export interface PayWithProps {
  setPaymentStep: (step: PaymentStepType) => void;
  amountDueCents: number;
}

export const PayWith: React.FC<PayWithProps> = ({
  setPaymentStep,
  amountDueCents,
}) => {
  return (
    <div className="flex w-full flex-none flex-col items-center gap-6 rounded-lg  mobile:w-full">
      <div className={"group/fe4ad82d flex w-full items-center gap-4"}>
        <span
          className={
            "grow shrink-0 basis-0 text-[18px] font-[500] leading-[28px] text-neutral-900"
          }
        >
          Pay with
        </span>
        <AsButton
          variant="ghost"
          text="Back"
          size="xs"
          onClick={() => {
            setPaymentStep(PaymentStepType.TIPS);
          }}
        />
      </div>
      <LineItems
        title="Amount due"
        price={`$${(amountDueCents / 100).toFixed(2)}`}
      />
      <AsButton
        className="h-14 w-full flex-none"
        variant="secondary"
        trailingIcon={null}
        text="Credit Card"
        size="lg"
        onClick={() => setPaymentStep(PaymentStepType.PAY_WITH_CC)}
      />
      {/* <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        trailingIcon={null}
        text="Pay"
        size="lg"
        loader={false}
      /> */}
    </div>
  );
};
