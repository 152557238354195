import { configureStore } from "@reduxjs/toolkit";
import { paymentLinkSlice } from "./paymentLinkSlice";
import { saleSlice } from "./saleSlice";

export const store = configureStore({
  reducer: {
    sale: saleSlice.reducer,
    paymentLink: paymentLinkSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UserState}
export type AppDispatch = typeof store.dispatch;
