"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Powered by — https://app.subframe.com/feb895689ba0/library?component=Powered+by_251400c5-8436-4c51-980b-bee2251053fb
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import IconLogo from "app/assets/icons/logo.svg";

interface PoweredByRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  text?: string;
  className?: string;
}

const PoweredByRoot = React.forwardRef<HTMLElement, PoweredByRootProps>(
  function PoweredByRoot(
    { image, text, className, ...otherProps }: PoweredByRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex items-center justify-center gap-1",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {text ? (
          <span className="text-[14px] font-[500] leading-[20px] text-neutral-200">
            {text}
          </span>
        ) : null}
        {image ? (
          <img className="flex-none" src={image} />
        ) : (
          <img className="flex-none" src={IconLogo} />
        )}
      </div>
    );
  }
);

export const PoweredBy = PoweredByRoot;
