"use client";
/*
 * Documentation:
 * Header — https://app.subframe.com/feb895689ba0/library?component=Header_d4b8c553-766f-4398-94c7-9bab930ea389
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface HeaderRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  text?: string;
  variant?: "default";
  blur?: "default";
  className?: string;
}

const HeaderRoot = React.forwardRef<HTMLElement, HeaderRootProps>(
  function HeaderRoot(
    {
      image,
      text,
      variant = "default",
      blur = "default",
      className,
      ...otherProps
    }: HeaderRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-full items-center gap-4 bg-white px-2 py-4",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {image ? (
          <img
            className="h-8 w-8 flex-none rounded-md object-cover"
            src={image}
          />
        ) : null}
        {text ? (
          <span className="text-body-bold font-body-bold text-default-font">
            {text}
          </span>
        ) : null}
      </div>
    );
  }
);

export const Header = HeaderRoot;
