"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Requested payment — https://app.subframe.com/feb895689ba0/library?component=Requested+payment_582e8ac6-d07c-4738-892a-9be5059957e8
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface RequestedPaymentRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  text2?: string;
  single?: boolean;
  className?: string;
}

const RequestedPaymentRoot = React.forwardRef<
  HTMLElement,
  RequestedPaymentRootProps
>(function RequestedPaymentRoot(
  {
    text,
    text2,
    single = false,
    className,
    ...otherProps
  }: RequestedPaymentRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/582e8ac6 flex w-full flex-col items-center",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex h-16 w-full flex-none flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-green-500 bg-millet-500 px-3 py-4">
        {text ? (
          <span className="text-[16px] leading-[24px] font-serif italic font-medium text-green-500">
            {text}
          </span>
        ) : null}
      </div>
      <div
        className={SubframeCore.twClassNames(
          "flex h-36 w-full flex-none flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-green-500 bg-millet-500 px-3 py-10",
          { "hidden border-2 border-dashed border-green-500": single }
        )}
      >
        {text2 ? (
          <span className="text-[48px] font-[500] leading-[56px] font-serif text-green-500">
            {text2}
          </span>
        ) : null}
      </div>
    </div>
  );
});

export const RequestedPayment = RequestedPaymentRoot;
