import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { Router } from "./routes/router";
import { AnimatePresence } from "framer-motion";
import "./App.css";

function App() {
  return (
    <>
      <AnimatePresence>
        <Router />
      </AnimatePresence>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        transition={Zoom}
        rtl={false}
        hideProgressBar={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
