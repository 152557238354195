import * as React from "react";
import classnames from "classnames";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import AsLoaderBrandRiv from "app/assets/rive/asLoaderBrand.riv";
import AsLoaderDarkRiv from "app/assets/rive/asLoaderDark.riv";

export interface LoadingProps {
  fullScreen?: boolean;
  label?: string;
  labelClassName?: string;
  showBackdrop?: boolean;
  containerClassName?: string;
  type?: "brand" | "dark";
}

export const Loading: React.FC<LoadingProps> = ({
  fullScreen = false,
  label,
  labelClassName,
  showBackdrop = false,
  containerClassName,
  type = "brand",
}) => {
  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: type === "brand" ? AsLoaderBrandRiv : AsLoaderDarkRiv,
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "play",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.Cover, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return (
    <div
      className={classnames(
        `flex flex-col relative items-center justify-center ${containerClassName}`,
        {
          "h-screen": fullScreen,
          "h-full": !fullScreen,
          "bg-[#171718]/80": showBackdrop,
        }
      )}
    >
      <RiveComponent className={"w-[80px] h-[80px]"} />
      <p
        className={classnames(labelClassName, {
          "text-white": showBackdrop,
          "text-neutral-900": !showBackdrop,
        })}
      >
        {label}
      </p>
    </div>
  );
};
