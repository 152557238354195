"use client";
/*
 * Documentation:
 * card info — https://app.subframe.com/feb895689ba0/library?component=card+info_d56da580-8331-4257-905f-6ffb320343ad
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface CardInfoRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  text?: string;
  variant?: "default" | "inverse";
  className?: string;
}

const CardInfoRoot = React.forwardRef<HTMLElement, CardInfoRootProps>(
  function CardInfoRoot(
    {
      image,
      text,
      variant = "default",
      className,
      ...otherProps
    }: CardInfoRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/d56da580 flex items-center justify-center gap-2",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {image ? (
          <img className="h-6 w-8 flex-none object-cover" src={image} />
        ) : null}
        {text ? (
          <span
            className={SubframeCore.twClassNames(
              "text-body-bold font-body-bold text-neutral-700",
              { "text-white": variant === "inverse" }
            )}
          >
            {text}
          </span>
        ) : null}
      </div>
    );
  }
);

export const CardInfo = CardInfoRoot;
