const styles = {
  container: `
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 16px;
    padding-bottom: 16px;
    height: 90px;
    width: 100%;
    position: relative;
    font-size: 16px;
  `,
  card: `
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #f1f1f2;
    border-radius: 6px;
    background-color: #ffffff;
    width: 96.5%; /* Ensure the card field takes up the full width */
    padding: 14px 0px 14px 12px;
    color: #71717A;
  `,
  expiryContainer: `
    display: flex;
    width: 46%;
    gap: 4px;
    border: 1px solid #f1f1f2;
    border-radius: 6px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 16px;
    overflow: hidden;
    padding: 3.5px 3px
  `,
  expiryMonth: `
    padding: 10px 0px 10px 10px;
    font-size: 16px;
    color: #71717A;
  `,
  expirySeparator: `
    align-content: center;
    color: #71717A;
  `,
  expiryYear: `
    padding: 10px 0px 10px 5px;
    font-size: 16px;
    color: #71717A;
    `,
  cvv2: `
    flex: 1; /* Align CVV on the same line */
    padding: 10px 0px 10px 10px;
    width: 43%;
    color: #71717A;
    border: 1px solid #f1f1f2;
    border-radius: 6px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 16px;
    padding: 14px 12px
  `,
  labels: `
    font-size: 16px;
    color: #6b7280;
    margin-bottom: 4px;
  `,
};

export default styles;
