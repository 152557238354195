import { PaymentStepType } from "app/payment/usePayment";
import { AsButton } from "app/subframe";
import * as React from "react";
import { usePayWithCc } from "./usePayWithCc";
import { SaleSliceState } from "app/redux/saleSlice";

export interface PayWithCcProps {
  setPaymentStep: (step: any) => void;
  paymentLinkInfo: any;
  sale: SaleSliceState;
  amountDueCents: number;
  tipAmountCents: number;
  setIsProcessingPayment: (isProcessingPayment: boolean) => void;
}

export const testCreditCards = [
  {
    cardType: "Visa",
    cardNumber: "4761530001111118",
    expirationDate: "12/25", // MM/YY
    cvv: "123",
    cardholderName: "John Doe",
    zipCode: 94016,
  },
  {
    cardType: "MasterCard",
    cardNumber: "5137221111116668",
    expirationDate: "11/24", // MM/YY
    cvv: "456",
    cardholderName: "Jane Smith",
    zipCode: 94016,
  },
];

export const PayWithCc: React.FC<PayWithCcProps> = ({
  setPaymentStep,
  paymentLinkInfo,
  sale,
  amountDueCents,
  tipAmountCents,
  setIsProcessingPayment,
}) => {
  const { onPayWithCcSubmit } = usePayWithCc({
    paymentLinkInfo,
    setPaymentStep,
    sale,
    amountDueCents,
    tipAmountCents,
    setIsProcessingPayment,
  });

  return (
    <form
      onSubmit={onPayWithCcSubmit}
      className="flex w-full flex-none flex-col items-center gap-6 rounded-lg  mobile:w-full"
    >
      <div className={"group/fe4ad82d flex w-full items-center gap-4"}>
        <span
          className={
            "grow shrink-0 basis-0 text-[18px] font-[500] leading-[28px] text-neutral-900"
          }
        >
          Pay with Credit Card
        </span>
        <AsButton
          variant="ghost"
          text="Back"
          size="xs"
          onClick={() => {
            setPaymentStep(PaymentStepType.PAY_WITH);
          }}
        />
      </div>
      <div className="flex w-full relative flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
        <span className="w-full text-body-bold font-body-bold text-neutral-900">
          CC Info
        </span>
        {/* <AsInput
          placeholder="Card number"
          showLeadingIcon={true}
          textPosition="left"
          name="cardNumber"
          maxLength={19}
          onChange={handleCardNumberChange} // No iframe update
        /> */}
        <div id="accept-blue-card-form" className="w-full h-28" />
        {/* <div className="grid grid-cols-2 gap-4">
          <AsInput
            textPosition="left"
            placeholder="CVV"
            maxLength={4}
            name="cvv"
            onChange={handleCvvChange} // No iframe update
          />
          <AsInput
            textPosition="left"
            placeholder="MM/YY"
            value={expiry}
            onChange={handleExpiryChange}
            maxLength={5} // MM/YY format
          />
        </div> */}
      </div>
      <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        text="Pay now"
        type="submit"
      />
    </form>
  );
};
