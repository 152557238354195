import { PaymentStepType } from "app/payment/usePayment";
import { AsButton } from "app/subframe";
import * as React from "react";

export interface InitialModalProps {
  setPaymentStep: (step: PaymentStepType) => void;
}

export const InitialModal: React.FC<InitialModalProps> = ({
  setPaymentStep,
}) => {
  return (
    <>
      <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        text="Looks good"
        size="lg"
        loader={false}
        disabled={false}
        disabledSecondary={false}
        onClick={() => setPaymentStep(PaymentStepType.TIPS)}
      />
    </>
  );
};
