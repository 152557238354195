"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Success well — https://app.subframe.com/feb895689ba0/library?component=Success+well_a6704a13-9ef9-4b52-b375-cce44a5ed1cf
 * Icon with background — https://app.subframe.com/feb895689ba0/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 * card info — https://app.subframe.com/feb895689ba0/library?component=card+info_d56da580-8331-4257-905f-6ffb320343ad
 * AsInput — https://app.subframe.com/feb895689ba0/library?component=AsInput_f45dfa71-d2b1-4826-bd47-2993766763a3
 * ASButton — https://app.subframe.com/feb895689ba0/library?component=ASButton_71931235-bb08-41fa-ad00-ec9684e17f79
 * Powered by — https://app.subframe.com/feb895689ba0/library?component=Powered+by_251400c5-8436-4c51-980b-bee2251053fb
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { IconWithBackground } from "./IconWithBackground";
import { CardInfo } from "./CardInfo";
import { PoweredBy } from "./PoweredBy";

interface SuccessWellRootProps extends React.HTMLAttributes<HTMLFormElement> {
  title?: string;
  invoiceTitle?: string;
  inviteValue?: string;
  paymentDateTitle?: string;
  paymentDateValue?: string;
  paymentMethodTitle?: string;
  className?: string;
  cardInfoElement?: React.ReactNode;
  emailInputElement?: React.ReactNode;
  emailButtonElement?: React.ReactNode;
}

const SuccessWellRoot = React.forwardRef<HTMLFormElement, SuccessWellRootProps>(
  function SuccessWellRoot(
    {
      title,
      invoiceTitle,
      inviteValue,
      paymentDateTitle,
      paymentDateValue,
      paymentMethodTitle,
      className,
      cardInfoElement,
      emailInputElement,
      emailButtonElement,
      ...otherProps
    }: SuccessWellRootProps,
    ref
  ) {
    return (
      <form
        className={SubframeCore.twClassNames(
          "flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full items-center gap-2">
          <IconWithBackground
            className="h-4 w-4 flex-none"
            variant="success"
            size="x-small"
            icon="FeatherCheck"
            square={false}
          />
          {title ? (
            <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
              {title}
            </span>
          ) : null}
        </div>
        <div className="flex w-full items-center gap-2">
          {invoiceTitle ? (
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              {invoiceTitle}
            </span>
          ) : null}
          {inviteValue ? (
            <span className="text-body-bold font-body-bold text-neutral-700">
              {inviteValue}
            </span>
          ) : null}
        </div>
        <div className="flex w-full items-center gap-2">
          {paymentDateTitle ? (
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              {paymentDateTitle}
            </span>
          ) : null}
          {paymentDateValue ? (
            <span className="text-body-bold font-body-bold text-neutral-700">
              {paymentDateValue}
            </span>
          ) : null}
        </div>
        <div className="flex w-full items-center gap-2">
          {paymentMethodTitle ? (
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              {paymentMethodTitle}
            </span>
          ) : null}
          {cardInfoElement ? (
            cardInfoElement
          ) : (
            <CardInfo
              image="https://res.cloudinary.com/subframe/image/upload/v1726598383/uploads/3988/dvl5twcraiwmn914brec.png"
              text="9999"
            />
          )}
        </div>
        {emailInputElement}
        {emailButtonElement}
        <PoweredBy
          image="https://res.cloudinary.com/subframe/image/upload/v1726585175/uploads/3988/uugigbcbw0ej0hiy77rr.svg"
          text="Powered by"
        />
      </form>
    );
  }
);

export const SuccessWell = SuccessWellRoot;
