import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "app/path";
import { ProtectedRoute } from "./protectedRoutes";
import { NotFound } from "app/components/notFound/notFound";
import { Playground } from "app/playground/playground";
import { Payment } from "app/payment/payment";
import { PaymentSuccess } from "app/payment/paymentSuccess/paymentSuccess";
// import { Home } from "app/home/home";
// import { Terms } from "app/legal/terms";
// import { Policy } from "app/legal/policy";

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={Path.HOME} element={<NotFound />} />
      <Route path={Path.PAYMENT} element={<Payment />} />
      <Route path={Path.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
      <Route path={Path.PLAYGROUND} element={<Playground />} />
      {/* <Route path={Path.SIGNUP} element={<Signup />} /> */}
      {/* <ProtectedRoute path={Path.SETTINGS} element={<Settings />} /> */}
      {/* <Route path={Path.TERMS} element={<Terms />} /> */}
      {/* <Route path={Path.POLICY} element={<Policy />} /> */}
    </Routes>
  );
};
