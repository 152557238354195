import { Path } from "app/path";
import { PaymentStepType } from "app/payment/usePayment";
import { generatePath } from "app/utils/generatePath";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import paymentFormStyle from "./paymentFormStyle";
import { useDispatch } from "react-redux";
import { payWithCreditCard } from "app/redux/paymentTransactionSlice";
import { SaleSliceState } from "app/redux/saleSlice";

export const usePayWithCc = ({
  paymentLinkInfo,
  setPaymentStep,
  sale,
  amountDueCents,
  tipAmountCents,
  setIsProcessingPayment,
}: {
  paymentLinkInfo: any;
  setPaymentStep: (step: PaymentStepType) => void;
  sale: SaleSliceState;
  amountDueCents: number;
  tipAmountCents: number;
  setIsProcessingPayment: (isProcessingPayment: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardFormRef = useRef<any>(null);

  const onPayWithCcSubmit = async (event: any) => {
    event.preventDefault();
    setIsProcessingPayment(true);
    try {
      const result = await cardFormRef.current.getNonceToken();
      const remainingTaxCents = sale.taxCents - sale.paidTaxCents;
      await dispatch(
        payWithCreditCard({
          source: `nonce-${result.nonce}`,
          amount: Number((amountDueCents / 100).toFixed(2)),
          totalCents: amountDueCents,
          subtotalCents: sale.subtotalCents,
          taxCents: remainingTaxCents,
          tipsCents: tipAmountCents,
          saleId: sale.id,
          name: sale.client.name,
          expiryMonth: result.expiryMonth,
          expiryYear: result.expiryYear,
          tip: Number((tipAmountCents / 100).toFixed(2)),
          tax: Number((remainingTaxCents / 100).toFixed(2)),
          paymentLink: paymentLinkInfo,
        }) as any
      ).unwrap();
      setIsProcessingPayment(false);
      navigate(
        generatePath(Path.PAYMENT_SUCCESS, { token: paymentLinkInfo.token })
      );
    } catch (error) {
      console.log(error);
      setIsProcessingPayment(false);
      setPaymentStep(PaymentStepType.PAYMENT_FAILED);
      return;
    }
  };

  useEffect(() => {
    const hostedTokenization = new window.HostedTokenization(
      paymentLinkInfo.paymentTokenizationKey
    );
    cardFormRef.current = hostedTokenization.create("card-form");
    cardFormRef.current.mount("#accept-blue-card-form");
    cardFormRef.current.setStyles(paymentFormStyle);
  }, [paymentLinkInfo]);

  return {
    onPayWithCcSubmit,
  };
};
