import * as React from "react";
import * as SubframeCore from "@subframe/core";
import { AsButton } from "app/subframe/components/AsButton";
import { PaymentStepType } from "app/payment/usePayment";

export interface PaymentFailedProps {
  setPaymentStep: (step: PaymentStepType) => void;
}

export const PaymentFailed: React.FC<PaymentFailedProps> = ({
  setPaymentStep,
}) => {
  return (
    <div className="flex w-full max-w-96 flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
      <div className="flex w-full items-center gap-2">
        <SubframeCore.Icon
          className="text-body font-body text-error-500"
          name="FeatherAlertTriangle"
        />
        <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
          Payment failed, try again or use a different payment method.
        </span>
      </div>
      <AsButton
        className="h-14 w-full flex-none"
        variant="secondary"
        trailingIcon={null}
        text="Credit Card"
        size="lg"
        onClick={() => setPaymentStep(PaymentStepType.PAY_WITH_CC)}
      />
      {/* <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        trailingIcon={null}
        text="Pay"
        size="lg"
        loader={false}
      /> */}
    </div>
  );
};
