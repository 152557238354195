"use client";
/*
 * Documentation:
 * Service line item — https://app.subframe.com/feb895689ba0/library?component=Service+line+item_d9304857-ff82-4c5d-86f2-5aec24500408
 * AsBadge — https://app.subframe.com/feb895689ba0/library?component=AsBadge_a23f3ea7-3649-4c1b-9f5a-e2094098f065
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { AsBadge } from "./AsBadge";

interface ServiceLineItemRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  serviceName?: string;
  conjunction?: string;
  staff?: string;
  price?: string;
  paidStatus?: "default" | "paid" | "unpaid";
  className?: string;
}

const ServiceLineItemRoot = React.forwardRef<
  HTMLElement,
  ServiceLineItemRootProps
>(function ServiceLineItemRoot(
  {
    serviceName,
    conjunction,
    staff,
    price,
    paidStatus = "default",
    className,
    ...otherProps
  }: ServiceLineItemRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/d9304857 flex w-full items-start justify-center gap-4",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
        <div className="flex w-full items-start gap-2">
          {serviceName ? (
            <span className="text-body-bold font-body-bold text-neutral-700">
              {serviceName}
            </span>
          ) : null}
          <AsBadge
            className={SubframeCore.twClassNames("hidden", {
              flex: paidStatus === "paid",
            })}
            variant="success"
            icon={null}
            iconRight={null}
          >
            Paid
          </AsBadge>
          <AsBadge
            className={SubframeCore.twClassNames("hidden", {
              flex: paidStatus === "unpaid",
            })}
            variant="warning"
            icon={null}
            iconRight={null}
          >
            Unpaid
          </AsBadge>
        </div>
        <div className="flex items-center gap-1">
          <span className="text-[14px] font-[400] leading-[20px] text-neutral-500">
            with
          </span>
          {staff ? (
            <span className="text-[14px] font-[500] leading-[20px] text-neutral-900">
              {staff}
            </span>
          ) : null}
        </div>
      </div>
      {price ? (
        <span className="text-body-bold font-body-bold text-neutral-900">
          {price}
        </span>
      ) : null}
    </div>
  );
});

export const ServiceLineItem = ServiceLineItemRoot;
