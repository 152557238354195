import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface PaymentLinkSliceState {
  id: number;
  sale: any;
  token: string;
  paymentTransaction: any;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const initialState = {} as PaymentLinkSliceState;

export const getPaymentLink = createAsyncThunk(
  "paymentLink/getPaymentLink",
  async (token: string) => {
    const { data } = await http.get(`/v1/payment_links/public_show/${token}`);
    return data;
  }
);

// export const createPaymentLink = createAsyncThunk(
// );

// export const updatePaymentLink = createAsyncThunk(
// );

// export const deletePaymentLink = createAsyncThunk(
// );

export const paymentLinkSlice = createSlice({
  name: "paymentLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentLink.fulfilled, (state, action) => {
      return action.payload;
    });
    // Add cases for async thunks here
  },
});

export const selectPaymentLink = (state: RootState) => state.paymentLink;
