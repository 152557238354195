import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface SaleSliceState {
  id: number;
  client: any; // Replace with a specific type if needed
  createdAt: string; // ISO date string
  createdBy: any; // Replace with a specific type if needed
  latestPendingPaymentLink: any; // Replace with a specific type if needed
  paidBalance: string; // Formatted currency string
  paidBalanceCents: number;
  paidMerchantServices: any[]; // Replace with a specific type if needed
  paidOnlineAmount: string; // Formatted currency string
  paidOnlineAmountCents: number;
  paymentLinks: any[]; // Replace with a specific type if needed
  paymentTransactions: any[]; // Replace with a specific type if needed
  salesMerchantServices: any[]; // Replace with a specific type if needed
  status: string;
  subtotal: string; // Formatted currency string
  subtotalCents: number;
  appointmentGroup: any;
  tax: string; // Formatted currency string
  taxCents: number;
  tips: string; // Formatted currency string
  tipsCents: number;
  total: string; // Formatted currency string
  totalCents: number;
  paidTax: string; // Formatted currency string
  paidTaxCents: number;
  paidTips: string; // Formatted currency string
  paidTipsCents: number;
  unpaidMerchantServices: any[]; // Replace with a specific type if needed
  updatedAt: string; // ISO date string
}

const initialState = {} as SaleSliceState;

export const getSale = createAsyncThunk(
  "sale/getSale",
  async (token: string) => {
    const { data } = await http.get(`/v1/sales/public_show/${token}`);
    return data;
  }
);

export const sendPostPaymentReceiptEmail = createAsyncThunk(
  "sale/sendPostPaymentReceiptEmail",
  async ({ email, token }: { email?: string; token: string }) => {
    const { data } = await http.post(
      `/v1/sales/send_post_payment_receipt_email`,
      {
        email,
        token,
      }
    );
    return data;
  }
);

// export const createSale = createAsyncThunk(
// );

// export const updateSale = createAsyncThunk(
// );

// export const deleteSale = createAsyncThunk(
// );

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSale.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const selectSale = (state: RootState) => state.sale;
