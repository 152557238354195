"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * ASButton — https://app.subframe.com/feb895689ba0/library?component=ASButton_71931235-bb08-41fa-ad00-ec9684e17f79
 * Loader — https://app.subframe.com/feb895689ba0/library?component=Loader_f2e570c8-e463-45c2-aae9-a960146bc5d5
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Loader } from "./Loader";

interface AsButtonRootProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "default"
    | "secondary"
    | "brand-ghost"
    | "brand"
    | "destructive-secondary"
    | "destructive-ghost"
    | "neutral-secondary"
    | "ghost"
    | "link";
  leadingIcon?: SubframeCore.IconName;
  trailingIcon?: SubframeCore.IconName;
  text?: string;
  size?: "default" | "md" | "lg" | "sm" | "xs";
  loader?: boolean;
  disabled?: boolean;
  disabledSecondary?: boolean;
  shadow?: boolean;
  cardComponent?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const AsButtonRoot = React.forwardRef<HTMLElement, AsButtonRootProps>(
  function AsButtonRoot(
    {
      variant = "default",
      leadingIcon = null,
      trailingIcon = null,
      text,
      size = "default",
      loader = false,
      disabled = false,
      disabledSecondary = false,
      shadow = false,
      cardComponent,
      className,
      children,
      ...otherProps
    }: AsButtonRootProps,
    ref
  ) {
    return (
      <button
        className={SubframeCore.twClassNames(
          "group/71931235 flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md border border-solid border-neutral-900 bg-neutral-900 px-3 py-2 hover:border hover:border-solid hover:border-neutral-700 hover:bg-neutral-700 active:border active:border-solid active:border-black active:bg-black",
          {
            "shadow-lg": shadow,
            "border border-solid border-neutral-400 bg-neutral-200 hover:border hover:border-solid hover:border-neutral-400 hover:bg-neutral-200 active:border active:border-solid active:border-neutral-400 active:bg-neutral-200":
              disabledSecondary,
            "border-none bg-neutral-200 hover:border-none hover:bg-neutral-200 active:border-none active:bg-neutral-200":
              disabled,
            "h-7 w-auto px-1 py-2": size === "xs",
            "h-8 w-auto": size === "sm",
            "h-14 w-auto": size === "lg",
            "h-12 w-auto": size === "md",
            "rounded-none border-none bg-transparent hover:border-none hover:bg-transparent active:border-none active:bg-transparent":
              variant === "link",
            "rounded-md border-none bg-transparent hover:rounded-md hover:border-none hover:bg-neutral-100 active:border-none active:bg-neutral-200":
              variant === "ghost",
            "border border-solid border-neutral-100 bg-white hover:border hover:border-solid hover:border-neutral-100 hover:bg-neutral-50 active:border active:border-solid active:border-neutral-100 active:bg-white":
              variant === "neutral-secondary",
            "border-none bg-transparent hover:border-none hover:bg-error-100 active:border-none active:bg-error-200":
              variant === "destructive-ghost",
            "border-none bg-error-50 hover:border-none hover:bg-error-200 active:border-none active:bg-error-50":
              variant === "destructive-secondary",
            "border border-solid border-brand-500 bg-brand-500 hover:border hover:border-solid hover:border-brand-600 hover:bg-brand-200 active:border active:border-solid active:border-brand-500 active:bg-brand-500":
              variant === "brand",
            "rounded-md border-none bg-transparent hover:border-none hover:bg-brand-100 active:border-none active:bg-brand-300":
              variant === "brand-ghost",
            "bg-white hover:border hover:border-solid hover:border-brand-900 hover:bg-brand-100 active:bg-brand-300":
              variant === "secondary",
          },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-body font-body text-white",
            {
              hidden: loader,
              "text-neutral-900": variant === "ghost",
              "text-neutral-900 group-active/71931235:text-brand-900":
                variant === "brand",
              "text-neutral-900 group-hover/71931235:text-brand-900 group-active/71931235:text-neutral-900":
                variant === "secondary",
            }
          )}
          name={leadingIcon}
        />
        <div
          className={SubframeCore.twClassNames(
            "hidden items-center justify-center gap-2",
            { flex: loader }
          )}
        >
          <Loader
            size="medium"
            className={SubframeCore.twClassNames("text-neutral-900", {
              "text-white": variant === "default",
            })}
          />
        </div>
        {text ? (
          <span
            className={SubframeCore.twClassNames(
              "text-body-bold font-body-bold text-white text-center",
              {
                "text-neutral-400": disabledSecondary || disabled,
                hidden: loader,
                "text-[14px] font-[500] leading-[20px]": size === "xs",
                "text-neutral-700 group-hover/71931235:text-neutral-900":
                  variant === "link",
                "text-neutral-900":
                  variant === "ghost" || variant === "neutral-secondary",
                "text-error-700":
                  variant === "destructive-ghost" ||
                  variant === "destructive-secondary",
                "text-neutral-900 group-active/71931235:text-brand-900":
                  variant === "brand",
                "text-neutral-900 group-hover/71931235:text-brand-900":
                  variant === "brand-ghost" || variant === "secondary",
              }
            )}
          >
            {text}
          </span>
        ) : null}
        {cardComponent}
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-body font-body text-white group-hover/71931235:text-white",
            {
              "text-neutral-900": variant === "link" || "ghost",
            }
          )}
          name={trailingIcon}
        />
        {children}
      </button>
    );
  }
);

export const AsButton = AsButtonRoot;
